import {Status} from '../enums'

export type Group = {
  id: string
  name: string
  status: Status
  groups?: Group[]
}

export type CategoriesTreeResponse = {
  businessAreas: Group[]
}

export type ResourceDetailsResponse = {
  specificKpiId: string
  name: string
  unit: ResourceUnit
  kpiGroup: KpiGroup
  values: Value[]
}[]

export interface ResourceUnit {
  id: number
  unitName: string
  abbreviation: string
}

export interface KpiGroup {
  id: string
  name: string
  businessAreaTypeId: number
  businessAreaName: string
}

export interface Value {
  date: string
  version: number
  value?: string
}
