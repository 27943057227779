export type PlantDetails = {
  sourceId: string
  siteId: string
  plantId: string
  name: string
  city: string
  street: string
  postCode: string
  region: string
  country: string
  longitude: string
  latitude: string
  timezone: string
  locationId: string
  source: string
  upmId: string
  id: string
}
